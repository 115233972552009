<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>用户信息管理</el-breadcrumb-item>
                <el-breadcrumb-item>用户信息</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="button-gutter">
                <!-- <el-button type="primary" @click="$router.push('/userregister')"
        >新增用户</el-button
      > -->
            </div>
            <div style="height: 800px">
                <el-table
                    :data="company"
                    :show-header="false"
                    style="width: 100%; margin-bottom: 20px"
                    @expand-change="clickTable"
                    :row-key="getRowKeys"
                    :expand-row-keys="expands"
               
                    max-height="800px"
                >
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-table v-loading="tableLoading" :data="tableData" v-show="scope['row'] === tableShow">
                                <el-table-column prop="username" label="用户名" align="center"> </el-table-column>
                                <el-table-column prop="name" label="姓名" align="center"> </el-table-column>
                                <el-table-column prop="email" label="邮箱" align="center"> </el-table-column>
                                <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
                                <el-table-column label="用户组" align="center">
                                    <template slot-scope="sco">
                                        {{ sco['row']['resume'] == 1 ? '超级管理员' : sco['row']['resume'] == 2 ? '海装风电' : '供应商' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="timeCreated" label="创建时间" align="center"> </el-table-column>
                                <el-table-column label="操作" align="center" width="280px">
                                    <template slot-scope="sco">
                                        <el-button
                                            style="margin-right: 5px"
                                            v-show="sco.row.id != userId"
                                            @click="showDialog(sco.row, sco.$index)"
                                            size="mini"
                                            type="primary"
                                            plain
                                        >
                                            编辑
                                        </el-button>
                                        <el-button
                                            v-show="sco.row.id != userId"
                                            style="margin-right: 12px"
                                            @click="PersonRestPass(sco.row, sco.$index)"
                                            size="mini"
                                            type="primary"
                                            plain
                                        >
                                            修改密码
                                        </el-button>

                                        <el-popconfirm
                                            confirm-button-text="确定"
                                            cancel-button-text="取消"
                                            confirm-button-type="success"
                                            icon="el-icon-info"
                                            icon-color="red"
                                            title="确定删除吗？"
                                            size="mini"
                                            @confirm="deleteFile(sco.row, scope)"
                                        >
                                            <el-button type="danger" plain size="mini" slot="reference" v-show="sco.row.id != userId"
                                                >删除</el-button
                                            >
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                    <el-table-column label="公司名称">
                        <template slot-scope="scope">
                            <div style="height: 50px; line-height: 50px">
                                <el-button type="text" style="color: #0a0a0a">{{ scope['row'] }}</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-paging" style="width: 560px; margin: 0 auto">
                <el-pagination
                    ref="paging"
                    style="margin-top: 10px"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                    @current-change="handlePageChange"
                />
            </div>
            <el-dialog title="编辑角色" :visible.sync="dialogVisible" width="30%" :before-close="Cancel">
                <el-form ref="resetForm" :model="resetForm" :rules="rules" size="medium" label-width="100px" label-position="left">
                    <el-form-item label="公司名称" prop="company">
                        <el-input v-model="resetForm.company" placeholder="请输入公司名称" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="username">
                        <el-input v-model="resetForm.username" placeholder="请输入账号" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="resetForm.name" placeholder="请输入姓名" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="phone">
                        <el-input v-model="resetForm.phone" placeholder="请输入手机号码" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="resetForm.email" placeholder="请输入邮箱" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="权限" prop="resume">
                        <el-select v-model="resetForm.resume" placeholder="请选择权限" clearable :style="{ width: '100%' }">
                            <el-option
                                v-for="(item, index) in field112Options"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <div class="footer">
                        <el-button @click="Cancel">取 消</el-button>
                        <el-button type="primary" @click="SendData('resetForm')">确 定 </el-button>
                    </div>
                </el-form>
            </el-dialog>
            <el-dialog title="修改密码" :visible.sync="restPassDialog" width="30%" :before-close="CancelPass">
                <el-form :model="resetPassword" status-icon :rules="passRule" ref="resetPassword" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="旧密码" prop="oldpassword">
                        <el-input
                            v-model="resetPassword.oldpassword"
                            placeholder="请输入旧密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="password">
                        <el-input
                            v-model="resetPassword.password"
                            placeholder="请输入新密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="aginnewPassword">
                        <el-input
                            v-model="resetPassword.aginnewPassword"
                            placeholder="请输入新密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="pass_footer">
                            <el-button @click="CancelPass">取消</el-button>
                            <el-button type="primary" @click="EnterData('resetPassword')">确定</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
let phonetext = /^[0-9]*$/;

let phone = (rule, value, callback) => {
    if (!phonetext.test(value)) {
        return callback(new Error('手机号格式不正确'));
    } else {
        callback();
    }
};
let emailtext = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;

let email = (rule, value, callback) => {
    if (!emailtext.test(value)) {
        return callback(new Error('邮箱格式不正确'));
    } else {
        callback();
    }
};
let notext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,12}$/;

let no = (rule, value, callback) => {
    if (!notext.test(value)) {
        return callback(new Error('工号格式数字加字母5~12位'));
    } else {
        callback();
    }
};
let passwordtext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;

let password = (rule, value, callback) => {
    if (!passwordtext.test(value)) {
        return callback(new Error('密码格式数字加字母8~16位'));
    } else {
        callback();
    }
};

let usernametext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{5,12}$/;

let username = (rule, value, callback) => {
    if (!usernametext.test(value)) {
        return callback(new Error('账号格式数字加字母5~12位'));
    } else {
        callback();
    }
};

let companytext = /^[\u4e00-\u9fa5]{4,16}$/;
let company = (rule, value, callback) => {
    if (!companytext.test(value)) {
        return callback(new Error('请输入正确的公司名称'));
    } else {
        callback();
    }
};

let nametext = /^[\u4e00-\u9fa5]{2,4}$/;

let name = (rule, value, callback) => {
    if (!nametext.test(value)) {
        return callback(new Error('请输入正确的名字'));
    } else {
        callback();
    }
};
import { companyList, companyUserList, getUserList, removeUserList, UpdateUserList } from '@/api/user.js';

export default {
    name: 'UserInfo',
    components: {},
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.resetPassword.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };

        return {
            getRowKeys(row) {
                return row;
            },
            // 要展开的行，数值的元素是row的key值
            expands: [],
            resetForm: {
                id: '',
                no: '',
                username: '',
                phone: '',
                email: '',
                resume: '',
                company: '',
                name: '',
                other: {}
            },
            openTable: null,
            company: [],
            resetPassword: {
                id: '',
                no: '',
                username: '',
                phone: '',
                email: '',
                resume: '',
                company: '',
                name: '',
                other: {},
                password: ''
            },
            tableShow: null,
            tableData: [],
            tableLoading: false,
            resumeOptions: [],
            userId: localStorage.getItem('userId'),
            resumeForm: [],
            otherForm: {},
            restPassDialog: false,
            dialogVisible: false,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            field112Options: [
                {
                    label: '供应商',
                    value: '3'
                },
                {
                    label: '海装风电',
                    value: '2'
                }
            ],
            rules: {
                name: [
                    {
                        type: 'string',
                        required: true,
                        validator: name,
                        trigger: 'blur'
                    }
                ],

                phone: [
                    {
                        type: 'number',
                        required: true,
                        validator: phone,
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        type: 'string',
                        required: true,
                        validator: username,
                        trigger: 'blur'
                    },
                    {
                        min: 5,
                        max: 12,
                        message: '长度在 5 到 12 个字符',
                        trigger: 'blur'
                    }
                ],
                company: [
                    {
                        type: 'string',
                        required: true,
                        validator: company,
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        type: 'string',
                        required: true,
                        validator: email,
                        trigger: 'blur'
                    }
                ],
                no: [
                    {
                        type: 'string',
                        required: true,
                        validator: no,
                        trigger: 'blur'
                    }
                ],
                resume: [{ required: true, message: '请选择用户权限', trigger: 'blur' }]
            },
            passRule: {
                password: [
                    {
                        type: 'string',
                        required: true,

                        validator: password,

                        trigger: 'blur'
                    }
                ],
                aginnewPassword: [
                    { required: true, message: '请确认密码', trigger: 'blur' },
                    {
                        type: 'string',
                        required: true,
                        validator: validatePass2,

                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    mounted() {
        // this.fetchUserList();
        this.fetchCompanyList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },

        async fetchUserList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await getUserList({
                limit,
                page
            });
            res['data'].forEach((item) => {
                item['hasChildren'] = false;
            });
            this.tableData = res.data;
            this.total = res.total;
            if (this.tableData.length > 0) {
                this.tableShow = res['data'][0]['company'];
            }
            this.num = parseInt(this.total / limit + 1);
        },

        PersonRestPass(row, $index) {
            this.restPassDialog = true;
            this.resetPassword.id = row.id;
            this.resetPassword.username = row.username;
            this.resetPassword.phone = row.phone;
            this.resetPassword.email = row.email;
            this.resetPassword.resume = row.resume;
            this.resetPassword.company = row.company;
            this.resetPassword.name = row.name;
            this.resetPassword.other = row.other;
            this.resetPassword.password = row.password;
        },

        async deleteFile(row, scope) {
            let id = row.id;
            let Removeres = await removeUserList({
                id
            });
            if (Removeres.code == 200) {
                this.$message.success('删除成功');
                this.test(scope);
            } else {
                this.$message.error('删除失败');
            }
        },
        async test(scope) {
            await companyUserList({
                company: scope['row']
            }).then((item) => {
                if (item['code'] == 200) {
                    this.tableData = item['data'];
                }
            });
        },

        //编辑弹框
        showDialog(row, $index) {
            this.resetForm = JSON.parse(JSON.stringify(row));
            this.dialogVisible = true;
        },
        Cancel() {
            this.dialogVisible = false;
            this.resetForm = {};
        },

        //确定编辑
        SendData(resetForm) {
            console.log(this.resetForm);
            this.$refs[resetForm].validate(async (valid) => {
                if (valid) {
                    console.log(this.valid);
                    let res = await UpdateUserList(this.resetForm);
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '用户信息修改成功'
                        });
                        this.tableData = res.data;
                        this.getList();
                        this.resetForm = {
                            id: '',
                            no: '',
                            username: '',
                            password: '',
                            phone: '',
                            email: '',
                            resume: '',
                            company: '',
                            other: {}
                        };
                        this.dialogVisible = false;
                    } else if (this.resetForm.company == null || this.resetForm.company == '') {
                        return this.$message.error('请输入正确的公司名字');
                    } else if (this.resetForm.name == null || this.formData.name == '') {
                        return this.$message.error('请填写正确的账号格式');
                    } else if (this.resetForm.username == null || this.resetForm.username == '') {
                        return this.$message.error('名字格式不正确');
                    } else if (this.resetForm.phone == null || this.formData.phone == '') {
                        return this.$message.error('请填写正确的手机号');
                    } else if (this.resetForm.email == null || this.formData.email == '') {
                        return this.$message.error('请填写正确的邮箱');
                    } else if (this.resetForm.resume == null || this.resetForm.resume == '') {
                        return this.$message.resume('请选择用户权限');
                    } else {
                        this.$message.error('用户添加失败请重新输入');
                        this.resetForm = {};
                    }
                }
            });
        },

        //修改密码

        EnterData(resetPassword) {
            console.log(this.resetPassword);
            let data = {};
            data.id = this.resetPassword.id;
            data.name = this.resetPassword.name;
            data.phone = this.resetPassword.phone;
            data.email = this.resetPassword.email;
            data.resume = this.resetPassword.resume;
            data.company = this.resetPassword.company;
            data.other = this.resetPassword.other;
            data.password = this.resetPassword.password;
            console.log(data);

            this.$refs[resetPassword].validate(async (valid) => {
                if (valid) {
                    let res = await UpdateUserList(data);
                    if (res.code == 200) {
                        this.restPassDialog = false;
                        this.resetPassword = {};
                        this.$message.success('修改用户密码成功');
                    } else {
                        this.restPassDialog = false;
                        this.resetPassword = {};
                        this.$message.error('修改失败请稍后重试');
                    }
                } else if (this.resetPassword.oldpassword == '' || this.resetPassword.oldpassword == undefined) {
                    return this.$message.error('请输入原始密码');
                } else if (this.resetPassword.password !== this.resetPassword.aginnewPassword) {
                    return this.$message.error('两次密码不一样，请重新输入');
                }
            });
        },
        // 取消修改
        CancelPass() {
            this.restPassDialog = false;
        },
        async collpaseChange(activeNames) {
            const response = await companyUserList({
                company: activeNames
            });
            this.tableData = response['data'];
        },

        async fetchCompanyUserList(company) {
            const response = await companyUserList({
                company: company
            });
            this.tableData = response['data'];
            this.tableLoading = false;
        },
        handleLimitChange() {},
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchCompanyList();
            this.tableData = [];
        },
        async fetchCompanyList() {
            const response = await companyList({
                page: this.currentPage,
                limit: this.pageSize
            });
            this.total = response['total'];
            this.company = response['data'];
        },
        clickTable(row, column, event) {
            console.log(row)
               const company = row;
                    if (this.tableShow != company) {
                        this.tableData = [];
                        this.fetchCompanyUserList(row);
                        this.tableShow = company;
                        return;
                    }
                    this.tableData = [];
                    this.tableShow = null;
            // if (this.expands.includes(row)) {
            //     this.expands = this.expands.filter((val) => val !== row);
            // } else {
            //     //判断是否已经存在展开的行
            //     if (this.expands.length != 0) {
            //         //如果存在展开行,清空expands数组,使它关闭
            //         this.expands.splice(0, this.expands.length);
            //         //打开点击的行
            //         this.expands.push(row);
            //         this.tableLoading = true;
            //         const company = row;
            //         if (this.tableShow != company) {
            //             this.tableData = [];
            //             this.fetchCompanyUserList(row);
            //             this.tableShow = company;
            //             return;
            //         }
            //         this.tableData = [];
            //         this.tableShow = null;
            //     } else {
            //         //如果不存在展开行,直接push打开点击的行
            //         this.expands.push(row);
            //     }
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}

::v-deep .el-dialog__header {
    text-align: center;
}

.footer {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
}

.pass_footer {
    width: 200px;
    text-align: center;
    margin: 0 auto;
}
</style>