import request from '@/utils/request'



// 用户登录

export function userlogin(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}

//创建用户

export function createuser(data) {
    return request({
        url: '/user/create',
        method: 'post',
        data
    })
}

//添加角色
export function addpermission(data) {
    return request({
        url: '/user/role/create',
        method: 'post',
        data
    })
}


// 获取用户

export function  getUserList(data) {
    return request({
        url: '/user/list',
        method: 'get',
        params:data
    })
}

//删除用户
export function  removeUserList(data) {
    return request({
        url: '/user/remove',
        method: 'get',
        params:data
        
    })
}

//更新用户信息

export function  UpdateUserList(data) {
    return request({
        url: '/user/update',
        method: 'post',
        data
        
    })
}


 // 用户组查询
 export function userOrgin(data) {
    return request({
      url: '/role/list',
      method: 'GET',
      params: data
    })
  }

  //更新用户组
export function  UpdateRoleList(data) {
    return request({
        url: 'role/update',
        method: 'post',
        data
        
    })
}

 //  供应链分组
 export function supplyChainList(data) {
    return request({
      url: 'supplierGroup/list',
      method: 'GET',
      params: data
    })
  }

export  function GetJournal(params){

    return request({
        url: '/user/log/list',
        method: 'get',
        params:{

        }
        
    })

}
  //文档查询
  export function getFilist(data) {
    return request({
      url: 'analyzeData/listDocCount',
      method: 'GET',
      params: data
    })
  }

  export function UserList(data) {
    return request({
      url: 'user/log/list',
      method: 'GET',
      params: data
    })
  }




  export function  userResetPass(data) {
    return request({
        url: 'user/user/password/update',
        method: 'post',
        data
        
    })
}

// 查询公司列表
export function companyList(params) {
    return request({
        url: '/user/company/list',
        method: 'get',
        params
    })
}

// 根据公司查询用户列表
export function companyUserList(params) {
    return request({
        url: '/user/user/info/list',
        method: 'get',
        params
    })
}

// 用户信息
export function userInfo() {
    return request({
        url: '/user/info',
        method: 'get'
    })
}